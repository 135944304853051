import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../Layout";
import MobileMenu from "./MobileMenu";

import "./header.css";

const Header = ({ children, isFixed, showProductsTab }) => {
  const { globalContent } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleBody = () => {
    if (isOpen) {
      document.documentElement.classList.add("no-scroll");
    } else {
      document.documentElement.classList.remove("no-scroll");
    }
  };

  const handleMobileClick = () => {
    document.documentElement.classList.remove("no-scroll");
    setIsOpen(false);
  };

  useEffect(() => {
    handleBody();
  }, [isOpen]);

  return (
    <>
      <MobileMenu
        isOpen={isOpen}
        onClick={handleMobileClick}
        data={globalContent}
        showProductsTab={showProductsTab}
      />
      <div
        className={`header__burger${isOpen ? " header__opened" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <header
        data-scroll-header
        className={`header ${isFixed ? "header--fixed" : ""}`}
      >
        {children && (
          <div className="container header__container">
            {React.cloneElement(children, {
              data: globalContent,
              showProductsTab
            })}
          </div>
        )}
      </header>
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  isFixed: PropTypes.bool,
  showProductsTab: PropTypes.bool
};

Header.defaultProps = {
  showProductsTab: true
};

export default Header;
