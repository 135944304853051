import React from "react";
import PropTypes from "prop-types";

import "./cookies-policy.css";

const CookiesPolicy = ({
  data: { cookie_headline: headline, cookie_content: content }
}) => (
  <section className="cookies-policy">
    <div className="container">
      <h1 className="cookies-policy__headline">{headline.text}</h1>
      <div
        className="cookies-policy__content"
        dangerouslySetInnerHTML={{ __html: content.html }}
      />
    </div>
  </section>
);

CookiesPolicy.propTypes = {
  data: PropTypes.shape({
    cookie_headline: PropTypes.object.isRequired,
    cookie_content: PropTypes.object.isRequired
  }).isRequired
};

export default CookiesPolicy;
