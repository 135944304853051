import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Header from "../components/Header";
import CookiesPolicy from "../components/CookiesPolicy";
import LocalizedLink from "../components/LocalizedLink";

const CookiesPage = ({ data: { cookie }, pageContext: { locale } }) => (
  <main>
    <Header>
      <LocalizedLink to="/">
        {locale === "fr-fr" ? "Accueil" : "Home"}
      </LocalizedLink>
    </Header>
    <CookiesPolicy data={cookie.data} />
  </main>
);

CookiesPage.propTypes = {
  data: PropTypes.shape({
    cookie: PropTypes.object.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string
  })
};

export const pageQuery = graphql`
  query CookieQuery($locale: String!) {
    cookie: prismicCookiesPolicy(lang: { eq: $locale }) {
      data {
        cookie_headline {
          text
        }
        cookie_content {
          html
        }
      }
    }
  }
`;

export default CookiesPage;
